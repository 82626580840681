/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Scrollbar from '@atoms/Scrollbar';
import { useIsAdmin } from '@common/application/hooks/useIsAdmin';
import useLocalStorage from '@common/application/hooks/useLocalStorage/useLocalStorage';
import styled from '@emotion/styled';
import AccountSearchDashboard from '@organisms/AccountSearchDashboard';
import AccountsTable from '@organisms/AccountsTable';
import CompanyDashboard from '@organisms/Dashboard/CompanyDashboard';
import DashboardLayout from '@organisms/Dashboard/DashboardLayout';
import Sidebar from '@organisms/Dashboard/Sidebar';
import UserDashboard from '@organisms/Dashboard/UserDashboard';
import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { css } from '@emotion/react';

interface DashboardProps {}

const StyledDashboard = styled.div`
  height: 100%;
  //background: #f1f1f1;
  background: #e8e8e8;
  // background: ${cssVars.lightBlue};
`;

export enum DashboardLink {
  USER = 'userDashboard',
  COMPANY = 'companyDashboard',
  SEARCH = 'searchAccounts',
}

function Dashboard(props: DashboardProps) {
  const [selectedLink, setSelectedLink] = useLocalStorage('trackup-dashboard-page', DashboardLink.USER);
  const { isAdmin } = useIsAdmin();
  const ComponentMap = useMemo(
    () => ({
      [DashboardLink.USER]: {
        component: UserDashboard,
        props: {},
      },
      [DashboardLink.COMPANY]: {
        component: CompanyDashboard,
        props: {},
        adminOnly: true,
      },
      [DashboardLink.SEARCH]: {
        component: AccountSearchDashboard,
        props: {},
      },
    }),
    [isAdmin],
  );

  useEffect(() => {
    if (ComponentMap[selectedLink].adminOnly && !isAdmin) {
      setSelectedLink(DashboardLink.USER);
    }
  }, [selectedLink, isAdmin, ComponentMap]);

  const SelectedComponent = ComponentMap[selectedLink].component;
  const selectedComponentProps = ComponentMap[selectedLink].props;

  return (
    <Scrollbar disableXScroll={true}>
      <StyledDashboard>
        <DashboardLayout>
          <Sidebar setSelectedLink={setSelectedLink} selectedLink={selectedLink} />
          <AnimatePresence exitBeforeEnter>
            <Scrollbar>
              <motion.div
                key={selectedLink}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                css={css`
                  //padding: 4px;
                  height: 100%;
                  box-sizing: border-box;
                `}
              >
                <SelectedComponent {...selectedComponentProps} />
              </motion.div>
            </Scrollbar>
          </AnimatePresence>
        </DashboardLayout>
      </StyledDashboard>
    </Scrollbar>
  );
}

export default Dashboard;
