/** @format */

import useTourSteps from '@common/application/hooks/useTourSteps/useTourSteps';
import styled from '@emotion/styled';
import AccountQuickNav from '@organisms/Dashboard/AccountQuickNav';
import DocumentQuickNav from '@organisms/Dashboard/DocumentQuickNav';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

interface UserDashboardProps {}

const StyledUserDashboard = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //grid-template-columns: repeat(2, 50%);
  //grid-auto-rows: 1fr 125px;
  padding: 8px 8px 8px 4px;
  box-sizing: border-box;
  gap: 8px;
`;

const NavSection = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  overflow: auto;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

const NavWrapper = styled.div`
  width: 50%;
  height: 100%;
`;

function UserDashboard(props: UserDashboardProps) {
  const router = useRouter();
  const { hide: hideTour, setIsTourOpen } = useTourSteps(router.pathname);
  const [userHasClosedTour, setUserHasClosedTour] = useState(false);

  useEffect(() => {
    if (!hideTour && !userHasClosedTour) {
      setIsTourOpen(true);
      setUserHasClosedTour(true);
    }
  }, [hideTour, userHasClosedTour]);

  return (
    <StyledUserDashboard>
      <NavSection>
        <NavWrapper className="account-nav">
          <AccountQuickNav />
        </NavWrapper>
        <NavWrapper className="doc-nav">
          <DocumentQuickNav />
        </NavWrapper>
      </NavSection>
    </StyledUserDashboard>
  );
}

export default UserDashboard;
