/** @format */

import styled from '@emotion/styled';
import LoadingSpinner from './LoadingSpinner';

interface CentredLoadingSpinnerProps {
  height?: number;
}

const StyledCentredLoadingSpinner = styled.div<{ height?: number }>`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  svg {
    height: ${({ height }) => `${height ? height : 50}px`};
  }
`;

function CentredLoadingSpinner(props: CentredLoadingSpinnerProps) {
  return (
    <StyledCentredLoadingSpinner {...props}>
      <LoadingSpinner />
    </StyledCentredLoadingSpinner>
  );
}

export default CentredLoadingSpinner;
