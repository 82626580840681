/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Link from '@atoms/Link';
import styled from '@emotion/styled';
import chroma from 'chroma-js';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

const CountUp = dynamic(() => import('react-countup'), { ssr: false });

interface AdminDashboardItemProps {
  quantity: number;
  label: string;
  href: string;
}

const StyledAdminDashboardItem = styled.div`
  height: 80px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${cssVars.white};
  padding: 4px;
  cursor: pointer;
  transition: transform 0.5s ease;
  //box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  :hover {
    transform: scale(1.02);
    outline: 1px solid ${cssVars.charcoal};
  }
`;

const Wrapper = styled.div`
  width: 30%;
`;

const Quantity = styled.span`
  font-size: 2em;
  font-weight: bold;
  border-radius: 3px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  flex-basis: 100%;
  width: 100%;
  color: ${cssVars.blue};
`;

const Label = styled.span`
  color: ${cssVars.charcoal};
  font-size: 1em;
`;

function AdminDashboardItem(props: AdminDashboardItemProps) {
  const router = useRouter();
  const lastQtyRef = useRef(0);

  return (
    <Wrapper>
      <Link href={props.href}>
        <StyledAdminDashboardItem>
          <Quantity>
            <CountUp
              duration={2}
              useEasing={true}
              start={lastQtyRef.current}
              end={props.quantity}
              onEnd={() => {
                lastQtyRef.current = props.quantity;
              }}
              preserveValue={true}
            />
          </Quantity>
          <Label>{props.label}</Label>
        </StyledAdminDashboardItem>
      </Link>
    </Wrapper>
  );
}

export default AdminDashboardItem;
