/** @format */

import { useSecureSession } from '@heimdall/react';
import { MutationFunction, MutationKey, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';

type UseSecureMutation =
  | (<TMutationKey extends MutationKey, TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
      mutationKey: TMutationKey,
      options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey'>,
    ) => UseMutationResult<TData, TError, TVariables, TContext>)
  | (<TMutationKey extends MutationKey, TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
      mutationKey: TMutationKey,
      mutationFn?: MutationFunction<TData, TVariables>,
      options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'>,
    ) => UseMutationResult<TData, TError, TVariables, TContext>);

const useSecureMutation: UseSecureMutation = <TMutationKey extends MutationKey = MutationKey, TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationKey: TMutationKey,
  mutationFn,
  options,
) => {
  const session = useSecureSession();
  const key = typeof mutationKey === 'string' ? [mutationKey, session.impersonatedRole] : [...mutationKey, session.impersonatedRole];
  return useMutation(key, mutationFn, options);
};

export default useSecureMutation;
