/** @format */

import CentredLoadingSpinner from '@atoms/CentredLoadingSpinner';
import LoadingSpinner from '@atoms/LoadingSpinner';
import Scrollbar from '@atoms/Scrollbar';
import DashboardDocListItem from '@organisms/Dashboard/DashboardDocListItem';
import React from 'react';
import styled from '@emotion/styled';
import { cssVars } from '@atoms/GlobalStyles/GlobalStyles';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import { DocumentClient } from '@common/model/apiClient';

interface RecentDocsProps {
  docs: Array<DocumentClient>;
  loading?: boolean;
}

const StyledRecentDocs = styled.div`
  background: ${cssVars.white};
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const RecentDocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  flex: 1;
  > div {
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    padding: 16px;
    gap: 16px;
  }
`;

const Header = styled.div`
  // background: ${cssVars.charcoal};
  // color: ${cssVars.white};
  // display: flex;
  align-items: center;
  // padding: 0 8px;
  border-radius: 3px 3px 0 0;
  color: ${cssVars.white};
  background: ${cssVars.charcoal};
  padding-inline: 8px;
  height: 45px;
  font-size: 1rem;
  display: flex;
  gap: 8px;
`;

function RecentDocs({ docs, loading }: RecentDocsProps) {
  return (
    <StyledRecentDocs>
      <Header>
        <span>
          <FormattedMessage defaultMessage={'Most Recent Documents'} description={'List of most recently created documents'} />
        </span>
      </Header>
      <div
        css={css`
          flex: 1;
          overflow: hidden;
          height: 100%;
        `}
      >
        {loading && <CentredLoadingSpinner />}
        <Scrollbar>
          {/*<RecentDocsWrapper>{!loading && docs.map((doc) => <div>{doc.id}</div>)}</RecentDocsWrapper>*/}
          <RecentDocsWrapper>{!loading && docs.map((doc) => <DashboardDocListItem key={`Document${doc.id}`} doc={doc} />)}</RecentDocsWrapper>
        </Scrollbar>
      </div>
    </StyledRecentDocs>
  );
}

export default RecentDocs;
