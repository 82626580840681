/** @format */

import DateTimePicker from '@atoms/DateTimePicker';
import FlexBox from '@atoms/FlexBox';
import { cssVars } from '@atoms/GlobalStyles';
import LoadingSpinner from '@atoms/LoadingSpinner';
import Scrollbar from '@atoms/Scrollbar';
import useSecureQuery from '@common/application/auth/useSecureQuery';
import { CurrencyContext } from '@common/application/context/CurrencyProvider';
import useDatePickerValue from '@common/application/hooks/useDatePickerValue/useDatePickerValue';
import useLocalStorage from '@common/application/hooks/useLocalStorage/useLocalStorage';
import { CompanyClient } from '@common/model/apiClient';
import { Document } from '@common/model/apiClient/Document';
import { User } from '@common/model/apiClient/User';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import RecentDocs from '@molecules/RecentDocs';
import AdminDashboard from '@organisms/Dashboard/AdminDashboard';
import CompanyLeaderboard from '@organisms/Dashboard/CompanyLeaderboard';
import DashboardDocListItem from '@organisms/Dashboard/DashboardDocListItem';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

const CountUp = dynamic(() => import('react-countup'), { ssr: false });

interface StatsProps {}

const StyledCompanyDashboard = styled.div`
  display: grid;
  //grid-template-columns: 300px repeat(2, calc(50% - 162px));
  grid-template-columns: clamp(150px, 20%, 240px) repeat(2, 1fr);
  grid-template-rows: 40px auto repeat(2, 1fr);
  grid-gap: 12px;
  max-width: 100%;
  border-radius: 3px;
  user-select: none;
  align-items: center;
  overflow: hidden;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  transition: grid-template-rows 0.4s ease;
  > div {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  @media (max-width: 768px) {
    //padding: 8px;
    //gap: 16px;
    display: flex;
    //flex-wrap: wrap;
    overflow-y: auto;
    //height: fit-content;
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    font-size: 0.7rem;
    padding: 8px 4px;
  }
`;

const StyledAdminDashboard = styled(AdminDashboard)`
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
`;

const CustomFiltersWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  transition: 300ms;
  background: ${cssVars.white};
  color: ${cssVars.black};
  width: 100%;
`;

const CustomFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const StatFilters = styled.div`
  background: ${cssVars.blue};
  //background: ${cssVars.blue};
  color: ${cssVars.white};
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-radius: 3px 3px 0 0;
  border: 1px solid ${cssVars.charcoal};
  border-bottom: 3px solid ${cssVars.charcoal};
  grid-column-start: 1;
  grid-column-end: 4;
  //grid-area: 1 / 1 / 2 / 4;
  box-sizing: border-box;
  height: fit-content;
  //height: 100%;
`;

const Filter = styled.div<{ selected: boolean }>`
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  font-size: 0.9em;
  display: grid;
  place-items: center;
`;

const FilterHighlight = styled(motion.div)`
  position: absolute;
  inset: 0px;
  //z-index: 1;
  border-radius: 3px 3px 0 0;
  border-inline: 3px solid ${cssVars.white};
  border-top: 3px solid ${cssVars.white};
  //padding-inline: 8px;
  box-sizing: border-box;
  background: ${cssVars.charcoal};
  // background: ${cssVars.lightBlue}33;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${cssVars.black};
  gap: 8px;
  @media (max-width: 768px) {
    padding: 4px;
    flex-direction: row;
  }
`;

const StatHeader = styled.div`
  color: ${cssVars.grey};
  font-size: 1em;
  flex: 1;
  //border-radius: 3px 3px 0 0;
  width: 50%;
  text-align: center;
  padding: 4px;
`;

const Stat = styled.span<{ textLength: number }>`
  border-bottom: 1px solid ${cssVars.blue};
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: ${cssVars.blue};
  //font-size: 1em;
  font-size: ${({ textLength }) => (textLength > 14 ? '1.5em' : textLength > 13 ? '1.6em' : '2.2em')};
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
  //aspect-ratio: 1;
`;

const StatGroup = styled.div`
  // background: ${cssVars.lightBlue};
  background: ${cssVars.white};
  color: ${cssVars.black};
  border-radius: 3px;
  padding: 8px 16px;
  //width: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-evenly;
  height: 100%;
  box-sizing: border-box;
  //width: 100%;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  @media (max-width: 768px) {
    padding: 4px;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    > div {
      height: 100%;
    }
  }
`;

const StatGroupHeader = styled.span`
  padding: 5% 15%;
  //width: 100%;
  //flex: 1;
  font-size: 1.1em;
  border-bottom: 2px solid ${cssVars.charcoal};
  @media (max-width: 768px) {
    border: none;
    width: 50%;
    padding: 0;
    text-align: center;
    align-content: center;
  }
`;

const LeaderboardWrapper = styled.div`
  height: 100%;
  border-radius: 3px;
  flex: 1;
`;

const ListsWrapper = styled.div`
  grid-area: 3/2/5/4;
  display: flex;
  height: 100%;
  gap: 12px;
  box-shadow: none !important;
  //display: contents;
  > div {
    min-width: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 4px;
    height: fit-content;
  }
`;

const today = new Date();

enum TimeFilter {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ALL = 'all',
  CUSTOM = 'custom',
}

function CompanyDashboard(props: StatsProps) {
  const [companySavingsTotal, setCompanySavingsTotal] = useState<number>(0);
  const [companyDocsTotal, setCompanyDocsTotal] = useState<number>(0);
  const [userSavingsTotal, setUserSavingsTotal] = useState<number>(0);
  const [userDocsTotal, setUserDocsTotal] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useLocalStorage('trackup-time-filter', TimeFilter.YEAR);
  const [recentDocs, setRecentDocs] = useState<Array<{ _source: Document; _id: string }>>([]);
  const [customStartDate, setCustomStartDate, getCustomStartDate] = useDatePickerValue(today);
  const [customEndDate, setCustomEndDate, getCustomEndDate] = useDatePickerValue(today);
  const { isFetching } = useSecureQuery(['company_recent_docs_query'], () => CompanyClient.getRecentDocuments(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    onSuccess: (d) => {
      setRecentDocs(d.payload.recentDocuments);
    },
  });

  const intl = useIntl();

  const { selectedCurrency } = useContext(CurrencyContext);

  const timeFilters = useMemo(() => {
    return [
      { id: TimeFilter.WEEK, label: 'Last 7 days', start: new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000), end: today },
      { id: TimeFilter.MONTH, label: 'This Month', start: new Date(today.getFullYear(), today.getMonth(), 1), end: today },
      { id: TimeFilter.YEAR, label: 'This Year', start: new Date(today.getFullYear(), 0, 1), end: today },
      { id: TimeFilter.ALL, label: 'All Time', start: new Date('0001-01-01'), end: today },
      { id: TimeFilter.CUSTOM, label: 'Custom', start: getCustomStartDate(), end: getCustomEndDate() },
    ];
  }, [customStartDate, customEndDate]);

  const filterRange = useMemo(() => {
    const filter = timeFilters.find((f) => f.id === selectedFilter);
    const { start, end } = filter;
    return { start, end };
  }, [selectedFilter, timeFilters]);

  const lastCompanySavingsRef = useRef(0);
  const lastCompanyDocsRef = useRef(0);
  const lastUserSavingsRef = useRef(0);
  const lastUserDocsRef = useRef(0);

  const session = useSecureSession<BaseRealmRole, BaseRole>();

  useQuery(['get_company_totals', filterRange], () => CompanyClient.getDocTotals(filterRange), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchInterval: 300000,
    onSuccess: (d) => {
      setCompanyDocsTotal(Number(d.payload.documentCount));
      setCompanySavingsTotal(Number(d.payload.totalCompanySavings));
    },
  });
  useQuery(['get_user_totals', filterRange], () => User.getStats(session.data.user.id, filterRange), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
    enabled: !!session?.data?.user,
    onSuccess: (d) => {
      if (userSavingsTotal === d.payload.savingsTotal) return;
      setUserDocsTotal(d.payload.docCount);
      setUserSavingsTotal(d.payload.savingsTotal);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const formatCurrency = useCallback(
    (value) => {
      return intl.formatNumber(value, { style: 'currency', currency: selectedCurrency?.id });
    },
    [intl, selectedCurrency?.id],
  );

  if (!selectedCurrency) {
    return <LoadingSpinner />;
  }

  return (
    // <DashboardItem>
    <StyledCompanyDashboard>
      <StatFilters>
        {timeFilters.map((filter) => (
          <Filter
            key={filter.id}
            selected={selectedFilter === filter.id}
            onClick={() => {
              setSelectedFilter(filter.id);
            }}
          >
            {selectedFilter === filter.id && <FilterHighlight layoutId={'filterUnderline'} />}
            <span
              css={css`
                z-index: 99;
              `}
            >
              {filter.label}
            </span>
          </Filter>
        ))}
      </StatFilters>
      <CustomFiltersWrapper style={{ height: selectedFilter === 'custom' ? '40px' : 0 }}>
        {selectedFilter === 'custom' && (
          <CustomFilters>
            <FlexBox gap={'8px'} alignItems={'center'}>
              Start Date: <DateTimePicker value={customStartDate} onChange={setCustomStartDate} />
            </FlexBox>
            <FlexBox gap={'8px'} alignItems={'center'}>
              End Date: <DateTimePicker value={customEndDate} onChange={setCustomEndDate} />
            </FlexBox>
          </CustomFilters>
        )}
      </CustomFiltersWrapper>
      <ListsWrapper>
        <LeaderboardWrapper>
          <CompanyLeaderboard filterRange={filterRange} />
        </LeaderboardWrapper>

        <LeaderboardWrapper>
          <RecentDocs docs={recentDocs.map((d) => d._source)} loading={isFetching} />
        </LeaderboardWrapper>
      </ListsWrapper>
      <StatGroup>
        <StatGroupHeader>
          <FormattedMessage defaultMessage={'Your Stats'} description={'Statistics for the user'} />
        </StatGroupHeader>
        <div>
          <StatContainer>
            <Stat textLength={userDocsTotal.toString().length}>
              <CountUp
                delay={1}
                // decimals={2}
                duration={1.2}
                useEasing={true}
                start={lastUserDocsRef.current}
                end={userDocsTotal}
                onEnd={() => {
                  lastUserDocsRef.current = userDocsTotal;
                }}
                // prefix={'EUR '}
                preserveValue={true}
              />
            </Stat>
            <StatHeader>
              <FormattedMessage defaultMessage={'Documents Created'} description={'Number of documents that have been created'} />
            </StatHeader>
          </StatContainer>
          <StatContainer>
            <Stat textLength={userSavingsTotal.toString().length}>
              <CountUp
                delay={1}
                decimals={2}
                duration={1.2}
                useEasing={true}
                start={lastUserSavingsRef.current}
                end={userSavingsTotal}
                onEnd={() => {
                  lastUserSavingsRef.current = userSavingsTotal;
                }}
                // prefix={'EUR '}
                preserveValue={true}
                formattingFn={formatCurrency}
              />
            </Stat>
            <StatHeader>
              <FormattedMessage defaultMessage={'Total Savings'} description={'Total value of all savings'} />
            </StatHeader>
          </StatContainer>
        </div>
      </StatGroup>
      <StatGroup>
        <StatGroupHeader>
          <FormattedMessage defaultMessage={'Company Stats'} description={'Statistics for the compamny'} />
        </StatGroupHeader>
        <div>
          <StatContainer>
            <Stat textLength={companyDocsTotal.toString().length}>
              <CountUp
                delay={1}
                // decimals={2}
                duration={1.2}
                useEasing={true}
                start={lastCompanyDocsRef.current}
                end={companyDocsTotal}
                onEnd={() => {
                  lastCompanyDocsRef.current = companyDocsTotal;
                }}
                // prefix={'EUR '}
                preserveValue={true}
              />
            </Stat>
            <StatHeader>
              <FormattedMessage defaultMessage={'Documents Created'} description={'Number of documents that have been created'} />
            </StatHeader>
          </StatContainer>
          <StatContainer>
            <Stat textLength={companySavingsTotal.toString().length}>
              <CountUp
                delay={1}
                decimals={2}
                duration={1.2}
                useEasing={true}
                start={lastCompanySavingsRef.current}
                end={companySavingsTotal}
                onEnd={() => {
                  lastCompanySavingsRef.current = companySavingsTotal;
                }}
                // prefix={'EUR '}
                preserveValue={true}
                formattingFn={formatCurrency}
              />
            </Stat>
            <StatHeader>
              <FormattedMessage defaultMessage={'Total Savings'} description={'Total value of all savings'} />
            </StatHeader>
          </StatContainer>
        </div>
      </StatGroup>
      <StyledAdminDashboard />
    </StyledCompanyDashboard>
    // </DashboardItem>
  );
}

export default CompanyDashboard;
