/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { useIsAdmin } from '@common/application/hooks/useIsAdmin';
import { CompanyClient } from '@common/model/apiClient';
import styled from '@emotion/styled';
import AdminDashboardItem from '@organisms/Dashboard/AdminDashboardItem';
import React from 'react';
import { useQuery } from 'react-query';

interface AdminDashboardProps {}

const StyledAdminDashboard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  gap: 4px 0;
  background: ${cssVars.white};
  //@media (min-width: 1024px) {
  grid-column-start: 1;
  grid-column-end: 4;
  //grid-column: span 2;
  //}
`;

function AdminDashboard(props: AdminDashboardProps) {
  const { isAdmin, isAdminChecked } = useIsAdmin();

  const dashboardStats = useQuery(['get_company_admin_dashboard'], () => CompanyClient.getDashboardStats(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    enabled: isAdmin && isAdminChecked,
    onSuccess: (d) => {
      // console.log(d);
    },
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <StyledAdminDashboard>
      <AdminDashboardItem
        quantity={dashboardStats?.data?.payload?.unapprovedIndustryCount ?? 0}
        label={'Industry Categories Awaiting Approval'}
        href={'/admin/accounts/#adminUnapprovedIndustryAccountTable'}
      />
      <AdminDashboardItem quantity={dashboardStats?.data?.payload?.unapprovedContactCount ?? 0} label={'Contacts Awaiting Approval'} href={'/admin/contacts/#approve'} />
      <AdminDashboardItem quantity={dashboardStats?.data?.payload?.missingAccountLogoCount ?? 0} label={'Accounts Missing Logos'} href={'/admin/accounts'} />
    </StyledAdminDashboard>
  );
}

export default AdminDashboard;
