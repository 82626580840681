/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from '@emotion/styled';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface EntityTagProps {
  label?: string;
  category: string;
  style: { color: string; background: string };
  onClick?(): void;
  button?: IconName;
}

const StyledEntityTag = styled.div<EntityTagProps>`
  color: ${(props) => props.style?.color};
  background: ${(props) => props.style?.background};
  border-radius: 5px;
  height: calc(1rem + 12px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: max-content;
  user-select: none;
  justify-content: space-between;
  padding: 3px;
`;

const TagButton = styled.span`
  cursor: pointer;
  background: ${cssVars.darkGrey};
  white-space: nowrap;
  padding-inline: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${cssVars.white};
  border-inline: 2px solid ${cssVars.darkGrey};

  > svg {
    height: 14px;
  }
`;

const TagCategory = styled.span<{ length: number }>`
  padding-inline: 4px;
  min-width: fit-content;
  font-size: ${({ length }) => (length > 18 ? '0.65rem' : '0.8rem')};
`;

const TagBody = styled.div`
  min-width: fit-content;
  background: ${cssVars.darkGrey};
  color: ${cssVars.white};
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 5px 5px 0;
  overflow: hidden;
`;

const Label = styled.div<{ length: number }>`
  font-size: ${({ length }) => (length > 18 ? '0.65rem' : '0.8rem')};
  padding-inline: 6px;
`;

function EntityTag(props: EntityTagProps) {
  return (
    <StyledEntityTag {...props}>
      {!!props.category?.length && <TagCategory length={props.category.length}>{props.category}</TagCategory>}
      <TagBody>
        {props.label && <Label length={props.label.length}>{props.label}</Label>}
        {props.button && (
          <TagButton>
            <FontAwesomeIcon icon={['fas', props.button]} />
          </TagButton>
        )}
      </TagBody>
    </StyledEntityTag>
  );
}

export default EntityTag;
