/** @format */

import useSecureMutation from '@common/application/auth/useSecureMutation';
import { User } from '@common/model/apiClient/User';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import Dashboard from '@organisms/Dashboard/Dashboard';
import React, { useEffect, useState } from 'react';

export function Index() {
  const [whatsNewShown, _setWhatsNewShown] = useState(!!JSON.parse(sessionStorage.getItem('popupShown')));
  const session = useSecureSession<BaseRealmRole, BaseRole>();

  const setWhatsNewShown = (val: boolean) => {
    sessionStorage.setItem('popupShown', JSON.stringify(!val));
    _setWhatsNewShown(!val);
  };

  const stopDate = new Date('2024-11-01').getTime();
  const now = Date.now();

  const transferSettingsToDB = useSecureMutation(['save_legacy_settings'], () => User.saveLegacySettings(session?.data?.user?.id));

  useEffect(() => {
    if (session?.data?.user?.id) {
      transferSettingsToDB.mutate();
    }
  }, [session?.data?.user?.id]);

  return (
    // <Scrollbar>
    //   <ContentWrapper layoutId="page-home">
    //     {/*{now < stopDate && <WhatsNewModal isShown={whatsNewShown} setIsShown={setWhatsNewShown} />}*/}
    //     <AccountsTable />
    //     <VSpacer />
    //   </ContentWrapper>
    // </Scrollbar>
    <Dashboard />
  );
}

export default Index;
