/** @format */

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface CollapseProps {
  children?: React.ReactNode;
  header: string | EmotionJSX.Element;
  defaultOpen?: boolean;
}

interface CollapseChildrenProps {
  open: boolean;
}

const StyledCollapseHeader = styled.span<CollapseChildrenProps>`
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    transition: all 0.3s ease;
    transform: rotate(${(props) => (props.open ? '90deg' : '0')});
  }
`;

const StyledCollapseBody = styled.div<CollapseChildrenProps>`
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  height: ${(props) => (props.open ? 'fit-content' : 0)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: visibility 0s, opacity 0.3s ease, height 0.3s ease;
  width: 100%;
`;

function CollapsibleContainer(props: CollapseProps) {
  const [open, setOpen] = useState(props.defaultOpen);
  return (
    <>
      <StyledCollapseHeader onClick={() => setOpen((open) => !open)} open={open}>
        <h3>{props.header}</h3>
        <FontAwesomeIcon icon={['far', 'chevron-right']} />
      </StyledCollapseHeader>
      <StyledCollapseBody open={open}>{props.children}</StyledCollapseBody>
    </>
  );
}

export default CollapsibleContainer;
