/** @format */

import Scrollbar from '@atoms/Scrollbar';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import AccountsTable from '@organisms/AccountsTable';

interface AccountSearchDashboardProps {}

const StyledAccountSearchDashboard = styled.div`
  padding: 12px 24px 12px 12px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
  > div {
    width: 100%;
  }
`;

function AccountSearchDashboard(props: AccountSearchDashboardProps) {
  return (
    <StyledAccountSearchDashboard>
      <Scrollbar
        wheelPropagation={true}
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            padding: 8px;
            height: 100%;
            position: relative;
            width: 100%;
            box-sizing: border-box;
          `}
        >
          <AccountsTable />
        </div>
      </Scrollbar>
    </StyledAccountSearchDashboard>
  );
}

export default AccountSearchDashboard;
